<template>
        
    <div class="col-lg-12 pd-0" :class="($i18n.locale == 'ar') ? '' : 'txt-left'">
        <button 
            v-if="!hasExit"
            type="button" 
            :disabled="loading"
            @click="submit"
            class="btn btn-primary"
            :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''">
            <span v-if="loading">
                <span 
                    class="spinner-grow spinner-grow-sm mr-1" 
                    role="status" 
                    aria-hidden="true">
                </span>
            </span>
            <i v-if="!loading" :class="
            (btnCurrent == 'Update' || btnCurrent == 'Send') 
                ? 'bx bx-edit' 
                : 'mdi mdi-plus-circle'"></i>&nbsp;
            <span v-if="!loading" v-html="
            (btnCurrent == 'Update') 
                ? $t('app.update') 
                :(
                    (btnCurrent == 'Send') 
                        ? $t('app.send')
                        : $t('app.addNew')
                )"></span>
        </button>
        &nbsp;
        <button 
            type="button" 
            class="btn btn-danger"
            :disabled="loading" 
            @click="cancel"
            :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''">
            <i class="mdi mdi-window-close"></i>&nbsp;
            {{ $t('app.cancel') }}
        </button>
    </div>
        
</template>

<script>
export default {
    name: 'Buttons',
    props: [
      'btnLoading',
      'btnCurrent',
      'hasExit'
    ],
    data(){
        return {
            loading: this.$props.btnLoading
        }
    },
    watch: {
        btnLoading: function(new_val) {
            this.loading = new_val ? new_val : false;
        }
    },
    mounted() {},
    created() {},
    methods: {

        submit(event) {
            this.loading = true;
            this.$emit('submitClicked', event)
        },

        cancel(event) {
            this.$emit('cancelClicked', event)
        }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

