var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12 pd-0",class:(_vm.$i18n.locale == 'ar') ? '' : 'txt-left'},[(!_vm.hasExit)?_c('button',{staticClass:"btn btn-primary",class:(_vm.$i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : '',attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.submit}},[(_vm.loading)?_c('span',[_c('span',{staticClass:"spinner-grow spinner-grow-sm mr-1",attrs:{"role":"status","aria-hidden":"true"}})]):_vm._e(),(!_vm.loading)?_c('i',{class:(_vm.btnCurrent == 'Update' || _vm.btnCurrent == 'Send') 
            ? 'bx bx-edit' 
            : 'mdi mdi-plus-circle'}):_vm._e(),_vm._v("  "),(!_vm.loading)?_c('span',{domProps:{"innerHTML":_vm._s(
        (_vm.btnCurrent == 'Update') 
            ? _vm.$t('app.update') 
            :(
                (_vm.btnCurrent == 'Send') 
                    ? _vm.$t('app.send')
                    : _vm.$t('app.addNew')
            ))}}):_vm._e()]):_vm._e(),_vm._v("   "),_c('button',{staticClass:"btn btn-danger",class:(_vm.$i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : '',attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.cancel}},[_c('i',{staticClass:"mdi mdi-window-close"}),_vm._v("  "+_vm._s(_vm.$t('app.cancel'))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }